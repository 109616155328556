.blog-section-pc-container {
  background-color: #f3f4f2;
  padding: 2.375rem 28.625rem 4.8125rem 5.125rem;
}

.blog-section-mobile-container {
  display: none;
}

.blog-section-pc-container h3.blog-main-header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 120%;
  margin-bottom: 0.75rem;
  color: #025d68;
}

.blog-section-pc-container h4.blog-main-subtext {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 3.4375rem;
  color: #2b2d42;
  max-width: 23%;
}

.blog-section-pc-container h3.blog-second-header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 110%;
  margin-bottom: 1.4375rem;
  color: #2b2d42;
}

.blog-section-pc-container .blog-news-cards .MuiPaper-root {
  max-width: 22.3125rem;
  border-radius: 1.25rem;
  background-color: #c9dadc;
  padding: 1.5rem;
}

.blog-section-pc-container .blog-news-cards .MuiPaper-root:nth-child(1) {
  margin-right: 1.625rem;
}

.blog-section-pc-container .blog-news-cards .MuiPaper-root h4 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 110%;
  margin-bottom: 0.5rem;
  color: #2b2d42;
}

.blog-section-pc-container .blog-news-cards .MuiPaper-root h5 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 0.75rem;
  color: #2b2d42;
}

.blog-section-pc-container .blog-news-cards .MuiPaper-root .MuiButtonBase-root {
  text-transform: capitalize;
  padding: 0;
  min-width: 0;
  color: #025d68;
}

@media (max-width: 768px) {
  .blog-section-pc-container {
    display: none;
  }

  .blog-section-mobile-container {
    display: block;
    background-color: #f3f4f2;
    padding: 3.9375rem 1.5rem 6.75rem 1.5rem;
  }

  .blog-section-mobile-container .blog-main-header {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 2.385rem;
    line-height: 120%;
    color: #025d68;
    margin-bottom: 1rem;
  }

  .blog-section-mobile-container .blog-main-subtext {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 120%;
    color: #2b2d42;
    margin-bottom: 3.4375rem;
  }

  .blog-section-mobile-container .blog-second-header {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 110%;
    color: #2b2d42;
    margin-bottom: 1rem;
  }

  .blog-section-mobile-container .blog-news-cards .MuiPaper-root {
    // max-width: 21.375rem;
    border-radius: 1.25rem;
    background-color: #c9dadc;
    padding: 1.5rem;
  }

  .blog-section-mobile-container .blog-news-cards .MuiPaper-root:nth-child(1) {
    margin-bottom: 1rem;
  }

  .blog-section-mobile-container
    .blog-news-cards
    .MuiPaper-root
    h4.blog-card-header {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 110%;
    color: #2b2d42;
  }

  .blog-section-mobile-container .blog-news-cards h5.blog-card-subtitle {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 120%;
    color: #2b2d42;
  }

  .blog-section-mobile-container .blog-news-cards h4.blog-card-subtext {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 120%;
    color: #2b2d42;
  }

  .blog-section-mobile-container
    .blog-news-cards
    .MuiPaper-root
    .MuiButtonBase-root {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 110%;
    color: #025d68;
    padding: 0;
    min-width: 0;
    text-transform: capitalize;
  }
}
