.navbar-pc-container {
  padding: 1.4375rem 5rem 1rem;
  background-color: #ffffff;
}

.navbar-mobile-container {
  display: none;
}

.MuiButtonBase-root.navbar-mobile-dropdown {
  min-width: 0;
  padding: 0;
}

.navbar-pc-container h4 {
  text-transform: capitalize;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  white-space: nowrap;
}

.logo-section img {
  margin-right: 0.11625rem;
}

.logo-section h6 {
  font-family: "Andada Pro", serif;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 110%;
  color: #025d68;
  margin-bottom: 0;
}

.grid-ish {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 2rem);
  grid-gap: 1rem 1.5rem;
}

// .grid-ish > * {
//   flex: 1 1 10em;
// }

.MuiButtonBase-root.message-btn,
.MuiButtonBase-root.call-btn {
  background-color: #025d68;
  min-width: 142px;
  height: 40px;
  border-radius: 8px;
}

.MuiButtonBase-root.message-btn:hover,
.MuiButtonBase-root.call-btn:hover {
  background-color: #09414e;
}

.navbar-pc-container h4.button-text {
  font-weight: bold;
}

.MuiButtonBase-root.dropdown-menu {
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  white-space: nowrap;
}

.dropdown-menu:hover {
  font-weight: bold;
}

@media (max-width: 768px) {
  .navbar-pc-container {
    display: none;
  }
  .navbar-mobile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3125rem 1.75rem 1.295rem 1.5625rem;
    background-color: #ffffff;
  }
}
