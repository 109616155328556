.services-section-pc-container {
  padding-left: 5rem;
  padding-right: 5rem;
  margin-bottom: 9.75rem;
}

.services-section-mobile-container {
  display: none;
}

.services-header {
  margin-bottom: 2.5rem;
}

.services-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 120%;
  color: #025d68;
}

.MuiTypography-root.service-card-title {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #2b2d42;
}

.MuiTypography-root.service-card-content {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  color: #2b2d42;
}

@media (max-width: 768px) {
  .services-section-pc-container {
    display: none;
  }

  .services-section-mobile-container {
    display: block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 6.27875rem;
  }

  .services-section-mobile-container .slick-slide {
    margin-right: 14px;
  }

  .slick-slider {
    white-space: nowrap;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: inline-block;
    white-space: normal;
  }
}
