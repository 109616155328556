.accommodation_section_banner {
  width: 100%;
}

.banner_row {
  padding-left: 0%;
  padding-right: 0%;
}

.banner_col {
  padding-top: 5%;
  background: #1d1d1d;
  padding-left: 8%;
  padding-right: 0%;
}

.banner_col h1 {
  color: #fff;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: 1.6px;
}
.banner_col h2 {
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: 0.96px;
  width: 80%;
}
.banner__image_col {
  padding-left: 0%;
  padding-right: 0%;
  background: linear-gradient(
    270deg,
    rgba(29, 29, 29, 0) 57.87%,
    #1d1d1d 91.16%
  ) !important;
}

.banner__image_col {
  position: relative;
}

// .banner__image_col img {
//   width: 100%;
//   display: block;
// }

.banner__image_col::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(29, 29, 29, 0) 57.87%,
    #1d1d1d 91.16%
  );
  pointer-events: none; /* Ensures that the overlay does not block interactions with the image */
}

.desktop_image {
  display: block;
  width: 100%;
}

.mobile_image {
  display: none;
}

@media (max-width: 768px) {
  .accommodation_section_banner {
    width: 100%;
  }

  .banner_row {
    padding-left: 5%;
    padding-right: 5%;
  }

  .banner_col {
    padding-top: 10%;
    padding-bottom: 10%;
    background: #f4f3f2;
    padding-left: 0%;
    padding-right: 0%;
  }

  .banner_col h1 {
    color: #025d68;
    font-family: Roboto;
    font-size: 38.156px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%; /* 45.787px */
    letter-spacing: 1.526px;
  }
  .banner_col h2 {
    color: #2b2d42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 153%; /* 24.48px */
    letter-spacing: 0.8px;
  }
  .banner__image_col {
    padding-left: 0%;
    padding-right: 0%;
    background: none !important;
  }

  .banner__image_col {
    position: relative;
  }

  // .banner__image_col img {
  //   width: 100%;
  //   display: block;
  //   border-radius: 4px;
  // }

  .banner__image_col::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none !important;
    pointer-events: none; /* Ensures that the overlay does not block interactions with the image */
  }

  .desktop_image {
    display: none;
  }

  .mobile_image {
    display: block;
    border-radius: 4px;
  }
}
