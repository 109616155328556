.ndis-section-pc-container {
  padding: 2.5rem 25rem 6.4375rem 5rem;
  background-color: #f3f4f2;
}

.ndis-section-mobile-container {
  display: none;
}

.ndis-section-pc-container .ndis-section-header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 110%;
}

.ndis-section-pc-container h4.ndis-section-date {
  line-height: 110%;
  margin-bottom: 2.5rem;
}

.ndis-section-pc-container h4,
.ndis-section-pc-container li {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
}

.ndis-section-pc-container h4,
.ndis-section-pc-container ul {
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .ndis-section-pc-container {
    display: none;
  }

  .ndis-section-mobile-container {
    display: block;
    padding: 2.5rem 2.5rem 6.4375rem 2.5rem;
  }

  .ndis-section-mobile-container .MuiBreadcrumbs-root a {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
    margin-bottom: 3.3125rem;
  }

  .ndis-section-mobile-container .MuiBreadcrumbs-root p {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
  }

  .ndis-section-mobile-container .ndis-section-header {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 110%;
    margin-bottom: 1.125rem;
    color: #2b2d42;
  }

  .ndis-section-mobile-container .ndis-section-date {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 110%;
    margin-bottom: 1.5rem;
    color: #2b2d42;
  }

  .ndis-section-mobile-container h4 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 110%;
    color: #2b2d42;
    margin-bottom: 1rem;
  }

  .ndis-section-mobile-container ul {
    margin-bottom: 1rem;
  }

  .ndis-section-mobile-container li {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 110%;
    color: #2b2d42;
  }

  .ndis-section-mobile-container li:last-child {
    margin-bottom: 0;
  }
}
