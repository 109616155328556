.contact-section-pc-container {
  padding: 3.0625rem 5rem 10.125rem 5rem;
  background-color: #f3f4f2;
}

.contact-section-mobile-container {
  display: none;
}

.contact-section-pc-container h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 120%;
  color: #025d68;
  margin-bottom: 2rem;
}

.contact-left,
.contact-right {
  width: 40%;
}

.contact-left h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  color: #2b2d42;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .contact-section-pc-container {
    display: none;
  }

  .contact-section-mobile-container {
    display: block;
    background-color: #f3f4f2;
    padding: 2.5rem 1.5rem 7.125rem 1.5rem;
  }

  .contact-section-mobile-container .contact-main-header {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 2.385rem;
    line-height: 120%;
    color: #025d68;
    margin-bottom: 0.6875rem;
  }

  .contact-section-mobile-container .contact-left {
    width: 95%;
    margin-bottom: 2.5rem;
  }

  .contact-section-mobile-container .contact-left h4 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 120%;
    color: #2b2d42;
  }

  .contact-section-mobile-container .contact-left h4:last-child {
    margin-bottom: 0;
  }

  .contact-section-mobile-container .contact-right {
    width: 100%;
  }
}
