.accommodation_Details_banner {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 8%;
  padding-right: 8%;
}

.details_heading_row {
  padding-bottom: 5%;
}

.details_heading_row h1 {
  color: #025d68;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: 1.6px;
}

.details_heading_row p {
  color: #2b2d42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
  width: 70%;
}

.details_content_row {
  width: 60%;
}

.details_content_row h1 {
  color: #2b2d42;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  letter-spacing: 0.96px;
}

.details_content_row p {
  color: #2b2d42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
  padding-bottom: 3%;
}

.details_content_row h2 {
  color: #2b2d42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
  padding-top: 2%;
}

.details_content_row ul li {
  color: #2b2d42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
  margin-left: 6%;
}
.empty_row {
  padding-top: 5%;
}

@media (max-width: 768px) {
  .accommodation_Details_banner {
    padding-top: 15%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .details_heading_row {
    padding-bottom: 5%;
  }

  .details_heading_row h1 {
    color: #025d68;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.96px;
  }

  .details_heading_row p {
    color: #2b2d42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 153%; /* 21.42px */
    letter-spacing: 0.7px;
    width: 100%;
  }

  .details_content_row {
    width: 100%;
  }

  .details_content_row h1 {
    color: #2b2d42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
    letter-spacing: 0.64px;
  }

  .details_content_row p {
    color: #2b2d42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
    padding-bottom: 3%;
  }

  .details_content_row h2 {
    color: #2b2d42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
    padding-top: 2%;
  }

  .details_content_row ul li {
    color: #2b2d42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
    margin-left: 6%;
  }
  .empty_row {
    padding-top: 5%;
  }
}
