.about-section-pc-container {
  padding: 5rem 14.25rem 8.5rem 5rem;
  background-color: #f3f4f2;
}

.about-section-mobile-container {
  display: none;
}

.about-section-pc-container h3.about-us-header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 120%;
  color: #025d68;
  margin-bottom: 1.75rem;
}

.about-section-pc-container h4.about-us-subtext {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1.29375rem;
  line-height: 144%;
  color: #2b2d42;
  max-width: 57.8%;
}

.about-section-pc-container .about-first-section {
  margin-bottom: 1.75rem;
}

.about-section-pc-container h4.about-final-subtext {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1.29375rem;
  line-height: 144%;
  color: #2b2d42;
  max-width: 55.7%;
}

@media (max-width: 768px) {
  .about-section-pc-container {
    display: none;
  }

  .about-section-mobile-container {
    display: block;
    padding: 2.5rem 1.375rem 10.3125rem 1.625rem;
    background-color: #f3f4f2;
  }

  .about-section-mobile-container .about-first-section {
    margin-bottom: 2.5rem;
  }

  .about-section-mobile-container .about-us-header {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 2.385rem;
    line-height: 120%;
    color: #025d68;
    margin-bottom: 0.75rem;
  }

  .about-section-mobile-container .about-us-subtext {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 150%;
    color: #2b2d42;
    margin-bottom: 1.5rem;
  }

  .about-section-mobile-container .MuiPaper-root.hospital-card {
    padding: 0.82625rem 0.6875rem 0.795625rem 0.6875rem;
  }

  .about-section-mobile-container .about-final-subtext {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 150%;
    color: #2b2d42;
    margin-bottom: 1.5rem;
  }
}
