.footer-section-pc-container {
  background-color: #f2e9e1;
}

.footer-section-mobile-container {
  display: none;
}

.footer-first-left,
.footer-first-middle {
  border-right: 1px solid rgba($color: #2b2d42, $alpha: 0.2);
}

.footer-first-left {
  width: 30%;
  height: 100%;
  padding: 2.5rem 5rem 6.85rem 5rem;
}

.logo-footer {
  margin-bottom: 2.13375rem;
}

.footer-first-left h5 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 144%;
  margin-bottom: 3.375rem;
}

.footer-first-left h6 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 110%;
  margin-bottom: 1rem;
}

h6.footer-first-left-header {
  font-family: "Andada Pro", serif;
  font-weight: 800;
  font-size: 1.769375rem;
  line-height: 110%;
  color: #025d68;
  margin-bottom: 0;
}

.footer-first-left .MuiButtonBase-root {
  min-width: 0;
  padding: 0;
}

.footer-first-middle {
  width: 40%;
  height: 100%;
  padding: 2.5rem 3.5625rem 2.75rem 3.9375rem;
}

.footer-first-middle h4.footer-middle-subheader {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 110%;
}

.footer-first-middle h4,
.footer-first-middle a.MuiTypography-root {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 110%;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #2b2d42;
}

.footer-first-middle h4:nth-last-child(1) {
  margin-bottom: 0;
}

.footer-first-right {
  width: 30%;
  height: 100%;
  padding: 2.5rem 6.875rem 16.125rem 4.5rem;
}

h5.footer-first-left-subtext {
  max-width: 52%;
}

.footer-first-right h4 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 0;
  white-space: nowrap;
}

.footer-first-right h4.footer-right-subheader {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 110%;
  margin-bottom: 1rem;
}

.footer-first-right div {
  margin-bottom: 0.75rem;
}

.footer-first-right img {
  margin-right: 0.25rem;
}

.footer-second-section {
  background-color: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.25rem 21.25rem 2.189375rem 21.625rem;
}

.footer-second-section h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 1rem;
  color: #ffffff;
}

.footer-second-section h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 1rem;
  color: rgba($color: #ffffff, $alpha: 0.5);
}

.footer-third-section {
  text-align: center;
  background-color: #f2e9e1;
  padding: 2rem 25.875rem 2.0625rem 26rem;
  position: relative;
}

.footer-third-section h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 120%;
  color: #2b2d42;
  margin-bottom: 0;
}

.footer-third-section .MuiButtonBase-root.go-up-btn {
  padding: 0;
  min-width: 0;
  position: absolute;
  top: 25%;
  right: 10%;
  transform: translate(-25%, 0);
}

.footer-third-section .MuiButtonBase-root.go-up-btn:hover {
  min-width: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .footer-section-pc-container {
    display: none;
  }

  .footer-section-mobile-container {
    display: block;
  }

  .footer-section-mobile-container .footer-first-section {
    background-color: #f2e9e1;
  }

  .footer-section-mobile-container .footer-first-left,
  .footer-first-middle {
    border-right: none;
    border-bottom: 1px solid rgba($color: #2b2d42, $alpha: 0.2);
  }

  .footer-section-mobile-container .footer-first-left {
    width: 100%;
    height: 100%;
    padding: 2.5rem 8.125rem 3.8525rem 1.5rem;
  }

  .footer-section-mobile-container .footer-first-middle {
    width: 100%;
    height: 100%;
    padding: 2.5rem 5.5rem 4.625rem 1.5rem;
  }

  .footer-section-mobile-container .footer-first-right {
    width: 100%;
    height: 100%;
    padding: 2.5rem 10.125rem 2.5rem 1.5rem;
  }

  .footer-section-mobile-container .footer-second-section {
    width: 100%;

    padding: 1.5rem 1.125rem 0.8125rem 1.125rem;
  }

  .footer-section-mobile-container .footer-third-section {
    width: 100%;
    padding: 2.5375rem 1.625rem 2.5rem 1.625rem;
    margin-bottom: 3.3125rem;
  }

  .footer-section-mobile-container
    .footer-third-section
    .MuiButtonBase-root.go-up-btn {
    display: none;
  }

  .footer-section-mobile-container .footer-final-cta {
    background-color: #ffffff;
    padding: 0.8125rem 1.5rem;
    box-shadow: 0 -8px 8px 0px rgba($color: #000000, $alpha: 0.25);
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  .footer-final-cta h4 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 0.875rem;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .footer-final-cta .MuiButtonBase-root.footer-call-btn {
    background-color: #025d68;
    min-width: 89px;
    height: 30px;
    border-radius: 4px;
  }
}
