.home-container {
  background-color: #f3f4f2;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.row .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}
