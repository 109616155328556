.hero-section-pc-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  background-color: #ffffff;
}

.hero-section-mobile-container {
  display: none;
}

// .hero-main-header {
//   max-width: 400px;
// }

.hero-main-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 38.16px;
  line-height: 120%;
  color: #025d68;
}

// .hero-subtext {
//   max-width: 478px;
// }

.hero-subtext h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 153%;
  color: #2b2d42;
}

.hero-left {
  width: 30%;
}

.hero-right {
  width: 50%;
}

// .hero-footer-text {
//   max-width: 407px;
// }

.hero-footer-text h4 {
  font-family: "Salsa", sans-serif;
  font-size: 24px;
  line-height: 120%;
  color: #2b2d42;
  text-align: center;
}

@media (max-width: 768px) {
  .hero-section-pc-container {
    display: none;
  }

  .hero-section-mobile-container {
    display: block;
    background-color: #f4f3f2;
  }

  .hero-section-mobile-container .MuiButtonBase-root {
    min-width: 10.40625rem;
    height: 3.25rem;
    border-radius: 8px;
  }

  .hero-section-mobile-container .message-btn h4,
  .hero-section-mobile-container .call-btn h4 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 110%;
    color: #f2e9e1;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .hero-mobile-text {
    padding: 2.5rem 1.3125rem;
  }

  .hero-mobile-text h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 2.385rem;
    line-height: 120%;
  }

  .hero-mobile-text .hero-subtext h5 {
    max-width: 90%;
  }
}
