.why-section-pc-container {
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;
  background-color: transparent;
  margin-bottom: 180px;
}

.why-section-mobile-container {
  display: none;
}

.why-main-header {
  padding-left: 45px;
  padding-right: 45px;
  position: absolute;
  background-color: #f3f4f2;
  top: -4%;
  left: 50%;
  transform: translate(-50%, 0);
}

.why-main-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #025d68;
  line-height: 120%;
  letter-spacing: 20%;
}

.why-section-cards {
  width: 30%;
}

.why-section-cards img {
  margin-right: 12px;
}

.why-card-header {
  margin-bottom: 8px;
}

.why-section-cards h5 {
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
}

.why-section-cards h6 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
}

@media (max-width: 768px) {
  .why-section-pc-container {
    display: none;
  }

  .why-section-mobile-container {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 5rem;
  }

  .why-section-mobile-container .why-main-header {
    top: -0.8%;
    padding-left: 3.59375rem;
    padding-right: 3.59375rem;
  }

  .why-section-mobile-container .why-main-header h3 {
    white-space: nowrap;
  }

  .why-section-mobile-container .why-section-cards {
    width: 100%;
  }
}
