.assist-section-pc-container {
  padding: 55px 138px 48px 88px;
  background-color: #c9dadc;
}

.assist-section-mobile-container {
  display: none;
}

.assist-left,
.assist-right {
  width: 40%;
}

.assist-left h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  color: #025d68;
  line-height: 120%;
  margin-bottom: 0.8125rem;
}

.assist-left h4 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  color: #2b2d42;
  line-height: 120%;
  margin-bottom: 0.8125rem;
}

.assist-left h4.assist-lasttext {
  margin-bottom: 2.3125rem;
}

@media (max-width: 768px) {
  .assist-section-pc-container {
    display: none;
  }

  .assist-section-mobile-container {
    background-color: #c9dadc;
    display: block;
    padding: 2.1875rem 1.5rem 3.125rem 1.5rem;
  }

  .assist-section-mobile-container .assist-left,
  .assist-section-mobile-container .assist-right {
    width: 100%;
  }

  .assist-section-mobile-container .assist-left h3 {
    font-weight: normal;
  }

  .assist-section-mobile-container .assist-left h4 {
    font-size: 0.875rem;
  }
}
