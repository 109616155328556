.chart-section-pc-container {
  display: block;
}

.chart-section-mobile-container {
  display: none;
}

@media (max-width: 768px) {
  .chart-section-pc-container {
    display: none;
  }

  .chart-section-mobile-container {
    display: block;
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    margin-bottom: 3.28375rem;
  }

  .chart-section-mobile-container .MuiPaper-root.mission-card {
    margin-bottom: 0.90375rem;
  }
}
