.ndis-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #035d68;
  padding: 2%;
  border-radius: 8px;
  color: white;
  flex-wrap: wrap;
  // margin: 40px 100px 0px 100px;
  margin: 3% 5% 0px 5%;
}

.ndis-logo {
  flex: 1 1 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.ndis-logo img {
  max-width: 100px;
  height: auto;
}

.ndis-text {
  flex: 3 1 200px;
}

.ndis-text h2 {
  margin: 0;
  font-size: 24px;
}

.ndis-text p {
  margin: 10px 0 0;
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ndis-section {
    padding-top: 5%;
    flex-direction: column;
    text-align: center;
  }

  .ndis-logo {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .ndis-text {
    flex: 3 1 140px;
  }

  .ndis-text h2 {
    font-size: 20px;
  }

  .ndis-text p {
    font-size: 14px;
  }
}

.mission-section-pc-container {
  padding: 7.1875rem 8.6875rem 11.375rem 5rem;
}

.mission-section-mobile-container {
  display: none;
}

.mission-header {
  max-width: 609px;
  margin-bottom: 24px;
}

.mission-header h3 {
  font-family: "Roboto", sans-serif;
  font-size: 33.79px;
  line-height: 120%;
  color: #025d68;
  margin-bottom: 0;
}

.mission-middle-section {
  max-width: 741px;
}

.mission-middle-section h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 20.7px;
  line-height: 144%;
  color: #2b2d42;
}

.MuiPaper-root.hospital-card {
  padding: 1.73125rem 1.52375rem 1.03375rem 1.375rem;
  border-radius: 24px;
  background-color: #c523a1;
}

.MuiPaper-root.hospital-card .hospital-card-inner img {
  margin-bottom: 12.84px;
}

.MuiPaper-root.hospital-card .hospital-card-inner p {
  font-family: "Merriweather", sans-serif;
  font-size: 10px;
  line-height: 120%;
  margin-bottom: 5.92px;
  color: #ffffff;
}

.MuiPaper-root.hospital-card .hospital-card-inner h5 {
  font-family: "Merriweather", sans-serif;
  font-weight: bold;
  font-size: 16.52px;
  line-height: 120%;
  color: #ffffff;
  margin-bottom: 8.77px;
}

.MuiPaper-root.mission-card img,
.MuiPaper-root.vision-card img {
  margin-bottom: 29px;
}

.MuiPaper-root.mission-card h4,
.MuiPaper-root.vision-card h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: #171c1c;
  margin-bottom: 14px;
}

.MuiPaper-root.mission-card h5,
.MuiPaper-root.vision-card h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #171c1c;
}

@media (max-width: 768px) {
  .mission-section-pc-container {
    display: none;
  }

  .mission-section-mobile-container {
    display: block;
    padding: 5rem 1.5625rem;
  }

  .mission-section-mobile-container .mission-header {
    margin-bottom: 0.75rem;
  }

  .mission-section-mobile-container .mission-header h3 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #025d68;
  }

  .mission-section-mobile-container .mission-middle-section {
    margin-bottom: 1.5rem;
  }

  .mission-section-mobile-container .mission-middle-section h5 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #2b2d42;
  }
}
