.career-section-pc-container {
  display: block;
}

.career-section-mobile-container {
  display: none;
}

.career-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5rem;
  background-color: #ffffff;
}

.career-left {
  width: 30%;
}

.career-left .career-main-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 120%;
  color: #2b2d42;
}

.career-left .career-subtext h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 122%;
  color: #2b2d42;
}

.career-right {
  width: 50%;
}

.career-bottom-section {
  background-color: #f3f4f2;
  padding: 9.0625rem 29.75rem 10.875rem 29.8125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.career-bottom-section h4 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 126%;
  max-width: 52%;
}

.career-bottom-section h4 a.mail-link {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 126%;
  text-decoration: none;
  color: #2b2d42;
}

@media (max-width: 768px) {
  .career-section-pc-container {
    display: none;
  }

  .career-section-mobile-container {
    display: block;
  }

  .career-section-mobile-container .career-top-section {
    flex-direction: column;
    padding: 2.5rem 1.5rem 3.75rem 1.5rem;
  }

  .career-section-mobile-container .career-left {
    width: 100%;
  }

  .career-section-mobile-container .career-right {
    width: 100%;
  }

  .career-section-mobile-container .career-bottom-section {
    padding: 2.5rem 1.5rem 3.75rem 1.5rem;
  }
}
