.important-section-pc-container {
  padding: 2.5rem 21.5625rem 9.8125rem 5rem;
  background-color: #f3f4f2;
}

.important-section-mobile-container {
  display: none;
}

.important-section-pc-container h3.important-section-header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 110%;
}

.important-section-pc-container .important-author-details {
  margin-bottom: 2.5rem;
}

.important-section-pc-container .important-author-details h4 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 110%;
}

.important-section-pc-container .important-author-details h4:nth-child(1) {
  margin-right: 2.8125rem;
}

.important-section-pc-container h4.important-initial-text {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 1.5rem;
}

.important-section-pc-container h4.important-faq-header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 0.75rem;
}

.important-section-pc-container h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 0.75rem;
}

.important-section-pc-container h5.important-first-answer,
.important-section-pc-container h5.important-second-answer {
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .important-section-pc-container {
    display: none;
  }

  .important-section-mobile-container {
    display: block;
    padding: 1.5rem 1.5rem 6.4375rem 1.5rem;
  }

  .important-section-mobile-container .MuiBreadcrumbs-root a {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
    margin-bottom: 3.3125rem;
  }

  .important-section-mobile-container .MuiBreadcrumbs-root p {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
  }

  .important-section-mobile-container .important-section-header {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 110%;
    margin-bottom: 1.125rem;
  }

  .important-section-mobile-container .important-author-details {
    margin-bottom: 2.5rem;
  }

  .important-section-mobile-container h4,
  .important-section-mobile-container h5 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 150%;
    color: #2b2d42;
  }

  .important-section-mobile-container .important-initial-text,
  .important-section-mobile-container .important-first-answer,
  .important-section-mobile-container .important-second-answer {
    margin-bottom: 2.5rem;
  }

  .important-section-mobile-container .important-faq-header {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .important-section-mobile-container .important-author-details h4 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 110%;
  }

  .important-section-mobile-container
    .important-author-details
    h4:nth-child(1) {
    margin-right: 1.75rem;
  }
}
